<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-3">
        <v-row class="justify-space-arround">
            <v-col cols="12">
                <v-card class="default" outlined height="100%">
                    <EditorToolbar
                        :headline="model.translations.find(x => x.locale === currentLanguage).title"
                        :isModified="isModified"
                        :isWaiting="isWaiting"
                        :editMode="editMode"
                        @onSaveItem="$emit('onSaveItem')"
                        @onDeleteItem="$emit('onDeleteItem')"
                        @onCreateItem="$emit('onCreateItem')"
                        @onFindUsage="$emit('onFindUsage')"
                        :buttons="['new', 'save', 'delete', 'usageAndReplacement', 'translationState']"
                        />
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col cols="12" xl="6">
                                 <v-card-text class="pa-0">
                                    <LeaTranslationTableInput v-model="model.translations" required autofocus @modified="$emit('modified', $event)" fieldValue="title" :fieldText="'label'" fieldType="text" />
                                </v-card-text>
                                <v-divider />
                                <v-card-text>
                                    <v-combobox
                                        v-model="personalDataModel"
                                        :label="$t('settings.personalDataCategory.personalDataField.fieldLabel')"
                                        :items="personalDataItems"
                                        hide-selected
                                        multiple
                                        item-text="title"
                                        item-value="id"
                                        @input="$emit('modified', $event)"
                                    >
                                        <template v-slot:selection="{ index }">
                                            <span
                                                v-if="index === 0"
                                                class="grey--text text-caption"
                                                >{{ model.personalData.length }}
                                                {{ $t('selected') }}</span
                                            >
                                        </template>
                                    </v-combobox>
                                </v-card-text>
                            </v-col>
                            <v-col cols="12" xl="6">
                                <v-card-title class="overline primary--text">{{ $t('settings.personalDataCategory.assignedPersonalData') }}</v-card-title>
                                <v-list dense>
                                    <template v-for="item in model.personalData">
                                        <v-hover :key="item.id">
                                            <v-list-item
                                                slot-scope="{ hover }"
                                                ripple
                                                @click="removePersonalData(item)"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        v-html="item.title"
                                                        :class="`${hover ? 'line-through' : ''}`"
                                                    ></v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-icon small v-if="hover" color="error">mdi-close</v-icon>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-hover>
                                    </template>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
import EditorToolbar from '../EditorToolbar.vue';
import LeaTranslationTableInput from '@/components/Input/LeaTranslationTableInput';

export default {
    name: 'SettingsPersonalDataEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        EditorToolbar,
        LeaTranslationTableInput
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        personalDataItems: {
            type: Array,
            default: () => []
        },
        availableLanguages: {
            type: Array,
            default: () => []
        },
        currentLanguage: {
            type: String,
            default: null
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isModified: {
            type: Boolean,
            default: false
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
        };
    },
    mounted() {
        if (isUUID(this.model.id)) {
            this.model.clientId = this.value.id;
        }
    },
    methods: {
        removePersonalData(item) {
            const index = this.model.personalData.findIndex(
                x => x.id === item.id
            );
            this.model.personalData.splice(index, 1);
            this.$emit('modified');
        }
    },
    computed: {
        personalDataModel: {
            get() {
                return this.model.personalData;
            },
            set(items) {
                let allowedItems = [];
                items.forEach(item => {
                    if(typeof item === 'object' && item !== null) {
                        allowedItems.push(item);
                    }
                });
                this.$set(this.model, 'personalData', allowedItems);
            }
        },
        editMode() {
            return !isUUID(this.model.id);
        },
        confLevel: {
            get() {
                if (this.model.confLevel === 9) {
                    return 6;
                }
                return this.model.confLevel;
            },
            set(val) {
                if (val === 6) {
                    this.model.confLevel = 9;
                } else {
                    this.model.confLevel = val;
                }
            },
        },
        confLevelHint() {
            if (this.model.confLevel === 0) {
                return this.$t('confLevel.undefinedProtectionRequirement');
            }
            if (this.model.confLevel === 1) {
                return this.$t('confLevel.publicProtectionRequirement');
            }
            if (this.model.confLevel === 2) {
                return this.$t('confLevel.lowProtectionRequirement');
            }
            if (this.model.confLevel === 3) {
                return this.$t('confLevel.mediumProtectionRequirement');
            }
            if (this.model.confLevel === 4) {
                return this.$t('confLevel.highProtectionRequirement');
            }
            if (this.model.confLevel === 5) {
                return this.$t('confLevel.veryHighProtectionRequirement');
            }
            if (this.model.confLevel === 9) {
                return this.$t('confLevel.article9ProtectionRequirement');
            }
            return this.$t('confLevel.undefinedProtectionRequirement');
        },
    },
};
</script>
